import React, { useState } from "react";
import Slider from "./products-filament-slider/pbt";
import SidePage from "../../components/get-a-quotation-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

export default function Pbt() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      {/* session - 1 */}
      <div>
        <center>
          <div className="sub-page-content">
            <div style={{ marginTop: "100px", marginBottom: "100px" }}>
              <div className="sub-page-two-column-para">
                <div className="sub-page-column-para sub-page-column1-para">
                  {/* Column 1 */}
                  <center>
                    <div>
                      <Slider />
                    </div>
                    <p className="products-filament-slider-para">
                      Remark: The above information is for reference only, the
                      client can customise the colour and shape based on their
                      needs.
                    </p>
                  </center>
                </div>
                <div className="sub-page-column-para sub-page-column2-para">
                  {/* Column 2 */}
                  <p className="sub-para-topic">
                    PBT (Polybutylene-Terephtalate)
                  </p>
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          It is softer and has somewhat of a lower strength and
                          stiffness than PET with good friction properties and
                          abrasion resistance. Furthermore, it is weatherproof
                          and has good stress corrosion resistance.
                        </p>
                      </td>
                    </tr>
                  </table>
                  <br />
                  <p className="sub-para-topic">
                    Chemical Resistance (At 20 °C)
                  </p>
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          To alkalis, strong acids, weak acids, alcohol and
                          organic solvents
                        </p>
                      </td>
                    </tr>
                  </table>
                  <br />
                  <p className="sub-para-topic">Fields Of Application</p>
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Car Wash Brooms</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Paint brushes</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Cosmetic</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Hygiene products</p>
                      </td>
                    </tr>
                  </table>
                  <br />
                  <p className="sub-para-topic">
                    Physical And Chemical Characteristics
                  </p>
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Density: 1.04 – 1.05 g/m2</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          Tensile Strength: 30-50 N/mm2
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          Elongation at Break: 1.0 – 5.0 %
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Melting Point: 240 ºC</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Wear Resistance: Good</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          Heat Resistance: 70 – 80 ºC
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          Available Diameters in MM: 0.16 – 1.60
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Bundle Wrap: LLDPE wrap</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          Bundle Diameter in MM: 50 - 60
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Cut Length in MM: 50 - 650</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          Hank Length in MM: 1,000-1,300
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Pack: Cartons or Poly Bags</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          Crimp & Amplitude: Heavy, medium and light amplitudes
                          can offered in all 3 crimp sizes
                        </p>
                        <p className="sub-para-p">• 2 : 2 crimps per inch</p>
                        <p className="sub-para-p">• 4 : 4 crimps per inch</p>
                        <p className="sub-para-p">• 6 : 6 crimps per inch</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Profile type</p>
                        <img
                          alt=""
                          src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/products-filament/profile-type-2.jpg"
                          className="sub-para-image"
                        />
                      </td>
                    </tr>
                  </table>

                  <div>
                    <div className="product-yarn-button-container">
                      <a
                        onClick={toggleSidebar}
                        className="products-filament-button-column"
                      >
                        <center>GET A QUOTATION</center>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>
      {isSidebarOpen && <SidePage closeSidebar={toggleSidebar} />}
    </div>
  );
}
