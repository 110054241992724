import React, { useState } from "react";
import Slider from "./products-filament-slider/polypropylene";
import SidePage from "../../components/get-a-quotation-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

export default function Polypropylene() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      {/* session - 1 */}
      <div>
        <center>
          <div className="sub-page-content">
            <div style={{ marginTop: "100px", marginBottom: "100px" }}>
              <div className="sub-page-two-column-para">
                <div className="sub-page-column-para sub-page-column1-para">
                  {/* Column 1 */}
                  <center>
                    <div>
                      <Slider />
                    </div>
                    <p className="products-filament-slider-para">
                      Remark: The above information is for reference only, the
                      client can customise the colour and shape based on their
                      needs
                    </p>
                  </center>
                </div>
                <div className="sub-page-column-para sub-page-column2-para">
                  {/* Column 2 */}
                  <p className="sub-para-topic">Polypropylene</p>
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          Widely available rigid polypropylene (PP) fibres have
                          low density and, therefore, score high on yield and
                          cost compared with other filaments. PP does not incept
                          moisture, pollution and smells. Its rigidity is
                          excellent even if wet and is neutral to most
                          dissolvents, oils, acids and chemical agents.
                        </p>
                      </td>
                    </tr>
                  </table>
                  <br />
                  <p className="sub-para-topic">
                    Chemical Resistance (At 20 °C)
                  </p>
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          Chemical resistance of PP is excellent.
                        </p>
                      </td>
                    </tr>
                  </table>
                  <br />
                  <p className="sub-para-topic">Fields Of Application</p>
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          Household and body-care brushes, brooms, industrial
                          and technical brushes.
                        </p>
                      </td>
                    </tr>
                  </table>
                  <br />
                  <p className="sub-para-topic">Products</p>
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Deck Brushes</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Street Brooms</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Outdoor Push Brooms</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Yard Brooms</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Scrub Brushes</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Scrub Brushes</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Cob web brushes</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Shoe brush</p>
                      </td>
                    </tr>
                  </table>
                  <br />
                  <p className="sub-para-topic">
                    Physical And Chemical Characteristics
                  </p>
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Density: 0.90 – 0.91 g/cm3</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Melting Point: 171 ºC</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          Vitrification Temperature of the Amorphous Phase: -15
                          ºC
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          Available Diameters in MM: 0.18 – 3.00
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Bundle Wrap: LLDPE wrap</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          Bundle Diameter in MM: 50 - 60
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Cut Length in MM: 50 - 650</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          Hank Length in MM: 1,000-1,300
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Pack: Cartons or Poly Bags</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">
                          Crimp & Amplitude: Heavy, medium and light amplitudes
                          can offered in all 3 crimp sizes
                        </p>
                        <p className="sub-para-p">• 2 : 2 crimps per inch</p>
                        <p className="sub-para-p">• 4 : 4 crimps per inch</p>
                        <p className="sub-para-p">• 6 : 6 crimps per inch</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "25px", verticalAlign: "top" }}>
                        <p className="ecos-page-sub-para-arrow">
                          <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </p>
                      </td>
                      <td style={{ width: "auto", verticalAlign: "top" }}>
                        <p className="sub-para-p">Profile type</p>
                        <img
                          alt=""
                          src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/products-filament/profile-type-1.jpg"
                          className="sub-para-image"
                        />
                      </td>
                    </tr>
                  </table>

                  <div>
                    <div className="product-yarn-button-container">
                      <a
                        onClick={toggleSidebar}
                        className="products-filament-button-column"
                      >
                        <center>GET A QUOTATION</center>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>
      {isSidebarOpen && <SidePage closeSidebar={toggleSidebar} />}
    </div>
  );
}
