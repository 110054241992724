import React, { useState } from "react";
import Pet from "./pet";
import Polypropylene from "./polypropylene";
import Ps from "./ps";
import Nylon from "./nylon"
import Pbt from "./pbt"
import "./monofilament-navbar.css";
import MobileBar from "./monofilament-navbar-mobile";

const NavBar = () => {
  const [selectedTab, setSelectedTab] = useState("pet");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleKeyDown = (e, tab) => {
    if (e.key === "Enter" || e.key === " ") {
      setSelectedTab(tab);
    }
  };

  const renderPage = () => {
    switch (selectedTab) {
      case "pet":
        return <Pet />;
      case "polypropylene":
        return <Polypropylene />;
      case "ps":
        return <Ps />;
      case "nylon":
        return <Nylon />;
      case "pbt":
        return <Pbt />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="pc">
        <nav className="navbar">
          <ul>
            <li
              role="button"
              tabIndex={0}
              className={selectedTab === "pet" ? "selected" : ""}
              onClick={() => handleTabClick("pet")}
              onKeyDown={(e) => handleKeyDown(e, "pet")}
            >
              <h2 className="tab-lable">PET</h2>
            </li>

            <li
              role="button"
              tabIndex={0}
              className={selectedTab === "polypropylene" ? "selected" : ""}
              onClick={() => handleTabClick("polypropylene")}
              onKeyDown={(e) => handleKeyDown(e, "polypropylene")}
            >
              <h2 className="tab-lable">POLYPROPYLENE</h2>
            </li>

            <li
              role="button"
              tabIndex={0}
              className={selectedTab === "ps" ? "selected" : ""}
              onClick={() => handleTabClick("ps")}
              onKeyDown={(e) => handleKeyDown(e, "ps")}
            >
              <h2 className="tab-lable">PS</h2>
            </li>

            <li
              role="button"
              tabIndex={0}
              className={selectedTab === "nylon" ? "selected" : ""}
              onClick={() => handleTabClick("nylon")}
              onKeyDown={(e) => handleKeyDown(e, "nylon")}
            >
              <h2 className="tab-lable">NYLON</h2>
            </li>

            <li
              role="button"
              tabIndex={0}
              className={selectedTab === "pbt" ? "selected" : ""}
              onClick={() => handleTabClick("pbt")}
              onKeyDown={(e) => handleKeyDown(e, "pbt")}
            >
              <h2 className="tab-lable">PBT</h2>
            </li>
          </ul>
        </nav>
        {renderPage()}
      </div>
      <div className="mobile">
        <MobileBar />
      </div>
    </div>
  );
};

export default NavBar;
