import React from "react";
import NavBar from "../../components/navigation-header";
import "../../styling/templates.css";
import MonofilamentNavbar from "./monofilament-navbar";
import Footer from "../../components/footer";

export default function productsFilament() {
  return (
    <div>
      <NavBar />

      <div style={{ marginBottom: "-3px" }}>
        <div style={{ position: "relative" }}>
          <img
            className="home-image"
            alt=""
            src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/products-filament/top-view-cleaning-brushes-with-copy-space.jpg"
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "80vh",
              zIndex: 0,
            }}
          ></div>
          <div className="video-text-box">
            <div className="video-background-text">
              <div style={{ marginLeft: "50%" }}>
                <h1 className="video-topic-2">
                  WE MANUFACTURE
                  <br />
                  HIGH-QUALITY SYNTHETIC MONOFILAMENTS FOR CLEANING
                  TOOLS/BRUSHES
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MonofilamentNavbar />

      <Footer />
    </div>
  );
}
